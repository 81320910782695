function api() {
    if (process.env.REACT_APP_ENV == "production") {
        ///// return 'https://neg.galaxy4g.com/admin/'
        ///// return 'http://149.248.56.110:3100/admin/'
        return 'https://lvapi.themakergroup.com/admin/'
    } else if (process.env.REACT_APP_ENV == "development") {
        return'http://93.188.35.254:3100/admin/'
    }
    return 'http://localhost:3000/admin/'
}

//not used ws
function ws() {
    if (process.env.REACT_APP_ENV == "production") {
        return 'ws://localhost:3101'
    } else if (process.env.REACT_APP_ENV == "development") {
        return'ws://localhost:3101'
    }
    return 'ws://localhost:3101'
}

export default {
    API: api,
    WS: ws
};