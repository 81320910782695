import React, {Component} from 'react';
import {
    Link, Redirect
} from "react-router-dom";
import {Formik, Field, FieldArray} from 'formik';
import _ from 'lodash';
import config from '../config';
import JsonEditorComponent from '../JsonEditorComponent'
import {AuthContext} from "../context/auth";

export default class Company extends Component {
    static contextType = AuthContext
    constructor(props, context) {
        super(props);
        this.authContext = context;
        this.state = {obj: null, id: null};
        this.readObj = this.readObj.bind(this);
        this.saveObj = this.saveObj.bind(this);
        this.removeObj = this.removeObj.bind(this);
    }

    saveObj(request) {
        this.setState({saving: true});
        request.subgroups = JSON.stringify(request.subgroupsArray)
        fetch(`${config.API()}companies/save`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.authContext.authToken,
                'Access-Control-Allow-Origin': '*',
                'Cross-Origin-Resource-Policy': 'cross-origin'
            },
            body: JSON.stringify(request)
        })
            .then(response => response.json())
            .then(data => {
                this.setState({saving: false});
                this.props.history.push(`/companies/${data.company.id}`);
            });

    }

    readObj(values) {
        if (values.id == "new") {
            setTimeout(() => {
                this.setState({
                    obj: {
                        id: "new",
                        name: "",
                        subgroups: []
                    },
                    id: "new"
                })
            }, 0)
            return;
        }
        fetch(`${config.API()}companies/${values.id}`)
            .then(response => response.json())
            .then(data => {
                if (data && data.company) {
                    data.company.subgroupsArray = JSON.parse(data.company.subgroups || "[]")
                    this.setState({
                        obj: data.company,
                        id: data.company.id
                    })
                }
                //setSubmitting(false);
            }).catch(function (err) {
            console.error(err);
        });
    }

    removeObj() {
        if (window.confirm("Confirm")) {
            const id = this.state.id;
            fetch(`${config.API()}companies/remove/${id}`, {cache: "no-cache"})
                .then(response => response.json())
                .then(data => {
                    this.props.history.push("/companies")
                }).catch(function (err) {
            });
        }
    }

    render() {

        let {id} = this.props.match.params;
        if (id && this.state.id != id) {
            this.readObj({id});
            return (<div></div>);
        }
        return (
            <div className="container-max-height">
                {this.state.obj && <Formik
                    initialValues={this.state.obj}
                    onSubmit={this.saveObj}>
                    {({
                          values,
                          handleSubmit,
                          handleChange,
                          isSubmitting
                      }) => (
                        <div className="card shadow w100">
                            <div className="card-header py-3">
                                <h2 className="m-0 font-weight-bold l">Company <span className="text-primary">{values.name}</span></h2>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    {values.id != "new" && <div className="form-group row">
                                        <label className="col-sm-2 col-form-label">Id</label>
                                        <div className="col-sm-10">
                                            {values.id}
                                        </div>
                                    </div>}
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label">Name</label>
                                        <div className="col-sm-10">
                                            <input className="form-control" type="text" name="name" onChange={handleChange} value={values.name}/>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-2 col-form-label"> Subgroups</label>
                                    </div>
                                    <FieldArray className="form-group row" name="subgroupsArray"
                                        render={arrayHelpers =>
                                            <div>
                                                <div className="form-group row">
                                                    <div className="col-1">id</div>
                                                    <div className="col-3">name</div>
                                                    <div className="col-1"></div>
                                                    <div className="col-3">registration id</div>
                                                </div>
                                                {values.subgroupsArray && values.subgroupsArray.map((item, index) =>
                                                    <div className="form-group row" key={"subgroupsArray" + index}>
                                                        <div className="col-1">
                                                            {item.id}
                                                        </div>
                                                        <div className="col-3">
                                                            <input type="text" name={`subgroupsArray[${index}].name`} className="form-control" onChange={handleChange} value={item.name}/>
                                                        </div>
                                                        <div className="col-1">
                                                            <button type="button" className="btn btn-sm btn-icon btn-circle btn-outline-danger" onClick={() => arrayHelpers.remove(index)}>
                                                                <span className="fas fa-fw fa-remove"></span>
                                                            </button>

                                                        </div>
                                                        {
                                                            values.id != "new" && <div className="col-4">
                                                                #{ new Buffer(`${values.id}_${item.id}`).toString('base64')}
                                                            </div>
                                                        }
                                                    </div>
                                                )}
                                                <button type="button" disabled={this.authContext.user && this.authContext.user.role == "read"} className="btn btn-icon btn-primary" onClick={() => arrayHelpers.push({ id: 99999+Math.floor(Math.random()*999999), name: ""})}>
                                                    <span className="fas fa-fw fa-plus"></span> Add
                                                </button>
                                            </div>
                                        }
                                    />

                                    {this.state.id != "new" && <button type="button" className="btn btn-icon btn-danger ml-1 r" onClick={this.removeObj}><span className="fas fa-fw fa-remove"></span> Remove </button>}
                                    <button type="submit" className="btn btn-icon btn-success ml-1 r"><span className="fas fa-fw fa-save"></span> {this.state.saving ? "Saving" : "Save"} </button>
                                </form>
                            </div>
                        </div>
                    )}
                </Formik>}
            </div>
        );
    }
}
