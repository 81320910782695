import React, {Component} from 'react';
import {
    Link
} from "react-router-dom";
import {Formik} from 'formik';
import _ from 'lodash';
import config from '../config';
import {AuthContext} from "../context/auth";

const dateFormat = require("dateformat");
export default class Companies extends Component {
    static contextType = AuthContext
    constructor(props, context) {
        super(props);
        this.authContext = context;
        this.state = {rows: null, count: 0, query: {}};
        this.getList = this.getList.bind(this);
        this.find = this.find.bind(this);
    }

    getList(query) {
        fetch(`${config.API()}companies?` + new URLSearchParams(Object.assign({
            page: 0
        }, query)),
            {
                method: 'GET',
                headers: {
                    'Authorization': this.authContext.authToken,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Cross-Origin-Resource-Policy': 'cross-origin'
                },
            })
            .then(response => response.json())
            .then(data => {
                this.setState({})
                this.setState({query: query, rows: data.rows, count: data.count})
            });
    }

    redirect(path) {
        return () => {
            this.props.history.push(path)
        }
    }

    find(values) {
        if (values.action === "byId") {
            this.props.history.push(`/companies?id=${values.id}`);
            return;
        }
        if (values.action === "byName") {
            this.props.history.push(`/companies?name=${values.id}`);
            return;
        }
    }

    render() {

        const query = new URLSearchParams(this.props.location.search);
        let newQuery = {
            id: query.get("id") || "",
            name: query.get("name") || "",
            page: query.get("page") || ""
        }
        let filters = _.clone(newQuery);
        delete filters["page"];

        if (!_.isEqual(newQuery, this.state.query)) {
            console.log("render", newQuery, this.state.query);
            this.getList(newQuery);
            return (<div></div>);
        }
        let pages = []
        let page = parseInt(this.state.query.page) || 0
        let lastPage = Math.floor(this.state.count / 1000)

        if(page == 0) {
            pages.push(page)
            if(page+1 <= lastPage)
                pages.push(page+1)
            if(page+2 <= lastPage)
                pages.push(page+2)
        }else if(page == lastPage){
            if(page-2 >=0)
                pages.push(page-2)
            if(page-1 >=0)
                pages.push(page-1)
            pages.push(page)
        }else{
            if(page-1 >=0)
                pages.push(page-1)
            pages.push(page)
            if(page+1 <= lastPage)
                pages.push(page+1)
        }

        return (
            <div>
                <div className="card shadow w100">
                    <div className="card-header py-3">
                        <h2 className="m-0 font-weight-bold">Companies</h2>
                    </div>
                    <div className="card-body">
                        {this.authContext.user.id == 1 && <div className="row">
                            <div className="col col-6">
                                <Formik
                                    initialValues={{id: ''}}
                                    onSubmit={this.find}
                                >
                                    {({
                                          values,
                                          handleSubmit,
                                          handleChange,
                                          submitForm,
                                          setFieldValue,
                                          isSubmitting
                                      }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-row align-items-center">
                                                <div className="col-6">
                                                    <input className="form-control"
                                                           type="text"
                                                           name="id"
                                                           onChange={handleChange}
                                                           value={values.id}
                                                    />
                                                </div>
                                                <div className="col-auto">
                                                    <button type="button" className="btn btn-icon btn-primary ml-1" onClick={() => {
                                                        setFieldValue("action", "byId");
                                                        submitForm();
                                                    }}>
                                                        <span className="fas fa-fw fa-search"></span> Find by id
                                                    </button>
                                                    <button type="button" className="btn btn-icon btn-success ml-1" onClick={() => {
                                                        setFieldValue("action", "byName");
                                                        submitForm();
                                                    }}>
                                                        <span className="fas fa-fw fa-search"></span> Find by name
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>}

                        <div className="row">
                            {Object.values(filters).some(x => !!x) &&
                            <div className="col col-12">
                                <div className="alert alert-info my-2">
                                    <div className="text-info">Filters</div>
                                    { Object.keys(filters).map((keyName, i) =>
                                        <div key={keyName}>{filters[keyName] && <div><span className="text-gray-600">{keyName}:</span> <b>{filters[keyName]}</b></div>}</div>
                                    )}
                                </div>
                            </div>}
                        </div>
                        <div className="row">
                            <div className="col col-12 pt-4">
                                <div className="l">
                                    {this.authContext.user.id == 1 && <button type="button" className="btn btn-icon btn-success ml-1" onClick={this.redirect("/companies/new")}>
                                        <span className="fas fa-fw fa-plus"></span> Create
                                    </button>}
                                </div>
                                <div className="r user-select-none">
                                    <ul className="pagination">
                                        <li className={"page-item " + (page == 0 ? "disabled" : "")}>
                                            {page != 0 && <Link className="page-link" to={"/companies?"+ new URLSearchParams(Object.assign(newQuery, {page:page-1}))}>Previous</Link>}
                                            {page == 0 && <span className="page-link">Previous</span>}
                                        </li>
                                        { pages.map((p,i) =>
                                            <li className={`page-item ${page == p ? "active" : ""}`}>
                                                <Link className="page-link" to={"/companies?" + new URLSearchParams(Object.assign(newQuery, {page:p}))}>{ p+1 }</Link>
                                            </li>
                                        )}
                                        <li className={"page-item " + (page == lastPage ? "disabled" : "")}>
                                            {page != lastPage && <Link className="page-link" to={"/companies?"+ new URLSearchParams(Object.assign(newQuery, {page:page+1}))}>Next</Link>}
                                            {page == lastPage && <span className="page-link">Next</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className="r p-2 pr-4">Showing {page+1} to {lastPage+1} of {this.state.count} companies</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-12 pb-2">
                                <div className="table-responsive">
                                    <table className="dataTable table table-align-middle table-bordered table-sm"
                                           width="100%"
                                           cellSpacing="0">
                                        <thead>
                                        <tr>
                                            <th>id</th>
                                            <th>name</th>
                                            <th>users</th>
                                            <th>created</th>
                                            <th>updated</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state && this.state.rows && this.state.rows.map((u, i) =>
                                            <tr key={ u.id}>
                                                <td onClick={this.redirect(`/users?company=${u.id}`)}>{u.id}</td>
                                                <td onClick={this.redirect(`/users?company=${u.id}`)}>{u.name}</td>
                                                <td onClick={this.redirect(`/users?company=${u.id}`)}>{u.users}</td>
                                                <td onClick={this.redirect(`/users?company=${u.id}`)}>{dateFormat(new Date(u.createdAt), "dd-mm-yyyy HH:MM:ss")}</td>
                                                <td onClick={this.redirect(`/users?company=${u.id}`)}>{dateFormat(new Date(u.createdAt), "dd-mm-yyyy HH:MM:ss")}</td>
                                                <td>
                                                    {this.authContext.user.role == "full" && <button type="button" className="btn btn-sm btn-icon btn-primary" onClick={this.redirect(`/companies/${u.id}`)}>
                                                        <span className="fas fa-fw fa-edit"></span> Edit
                                                    </button>}
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
