import React, {Component} from 'react';
import {
    Link
} from "react-router-dom";
import {Formik} from 'formik';
import _ from 'lodash';
import config from '../config';
import {AuthContext} from "../context/auth";

const dateFormat = require("dateformat");
export default class UsersList extends Component {
    static contextType = AuthContext
    constructor(props, context) {
        super(props);
        this.authContext = context;
        this.tableCols = ["login", "company", "subgroup","total","level1","level2","level3","level4","updated","created", "percents"]
        this.state = {users: null, count: 0, query: {}, companies: null};
        this.getList = this.getList.bind(this);
        this.find = this.find.bind(this);
        this.sort = this.sort.bind(this);
        this.selectTableCol = this.selectTableCol.bind(this);
        this.getCompanies = this.getCompanies.bind(this);
        this.getScores = this.getScores.bind(this);
        this.getScores();
        this.getCompanies();

    }
    getScores( callback)  {
        fetch(`${config.API()}maxScores`)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if(data && data.scores) {
                    this.setState(data)
                }else{
                    this.setState({scores: [{l: 0, m: 0, t: 0,}, {l: 0, m: 0, t: 0}, { l: 0, m: 0, t: 0}, {l: 0, m: 0, t: 0}]})
                }
            }).catch(function(err) {
        });
    }
    static getScoreFromEpisode(ep){
        let sumEp = 0;
        if(ep)
        for(let x of ep){
            if(x && x.md)
                sumEp += UsersList.getScoreFromMiniGames(x.md)
        }
        return sumEp;
    }

    static getScoreFromMiniGames(x){
        let sumEp = 0;
        if(Array.isArray(x))
            for(let y of x){
                sumEp += y.p || 0;
            }
        return sumEp;
    }
    getCompanies() {
        fetch(`${config.API()}companies?limit=5000`)
            .then(response => response.json())
            .then(data => {
                for(let r of data.rows){
                    r.subgroupsArray = JSON.parse(r.subgroups || "[]");
                }
                this.setState({companies: data.rows})
            })
    }
    getList(query) {
        fetch(`${config.API()}users?` + new URLSearchParams(Object.assign({
            page: 0
        }, query)),
            {
                method: 'GET',
                headers: {
                    'Authorization': this.authContext.authToken,
                    'Access-Control-Allow-Origin': '*',
                    'Cross-Origin-Resource-Policy': 'cross-origin'
                },
            })
            .then(response => response.json())
            .then(data => {
                for(let user of data.rows){
                    //if(query.company && user.company_name)
                    //    companyName = user.company_name;

                    let c = _.find(this.state.companies, x => x.id == user.company);
                    if(c) {
                        user.companyName = c.name;
                        user.subgroupName = (_.find(c.subgroupsArray,x => x.id == user.subgroup) || {name:""}).name;
                    }

                    let total = 0;
                    let maxTotal = 0
                    for(let i = 0;i<4;i++){
                        let n = i+1;
                        user["l"+n] = UsersList.getScoreFromEpisode( _.get(user, `state.lsd[${i}]ep`));
                        user["mgl"+n] = UsersList.getScoreFromMiniGames( _.get(user, `state.lsd[${i}]ng`));
                        user["tl"+n] = UsersList.getScoreFromMiniGames( _.get(user, `state.lsd[${i}]ftg`));
                        total += user["l"+n] + user["mgl"+n] + user["tl"+n]
                        maxTotal +=  this.state.scores[i].l + this.state.scores[i].m + this.state.scores[i].t
                    }
                    user.totalScore = total;
                    user.maxTotalScore = maxTotal;
               }
                let tableCols = ["login", "company", "subgroup", "total", "created", "percents"];
                if(query.company){
                    tableCols = ["login", "subgroup", "total", "created", "level1", "level2", "level3", "level4", "percents"];
                }
                this.setState({query: query, users: data.rows, count: data.count, tableCols })
            });
    }

    selectTableCol(tableCol) {
        return () => {
            let array = JSON.parse(JSON.stringify(this.state.tableCols));
            if(this.state.tableCols.includes(tableCol)){
                array = array.filter(x => x != tableCol)
            }else{
                array.push(tableCol)
            }
            this.setState({tableCols: array})
        }
    }

    redirect(path) {
        return () => {
            this.props.history.push(path)
        }
    }

    sort(sortKey) {
        return () => {
            console.log("SORT", sortKey, this.state.sortKey)
            let users = []

            if (sortKey == 'idk') {
                users = _.sortBy(this.state.users, [function(eachUser) { return parseInt(eachUser.idk); }])
            }
            else {
                users = _.sortBy(this.state.users, sortKey)
            }
                
            let sortAsc = false;
            if(sortKey == this.state.sortKey){
                sortAsc = !this.state.sortAsc;
                if(sortAsc)
                    _.reverse(users)
            }
            this.setState({sortKey, sortAsc, users});
        }
    }

    find(values) {
        if (values.action === "byId") {
            this.props.history.push(`/users?id=${values.id}`);
            return;
        }
        if (values.action === "byName") {
            this.props.history.push(`/users?login=${values.id}`);
            return;
        }
    }

    showModalTableCols(){
        window.jQuery('#tableCols').modal('show');
    }

    render() {
        if(!this.state.companies)
            return null;
        if(!this.state.scores)
            return null;

        const query = new URLSearchParams(this.props.location.search);
        let newQuery = {
            company: query.get("company") || "",
            subgroup: query.get("subgroup") || "",
            name: query.get("name") || "",
            page: query.get("page") || "",
            id: query.get("id") || ""
        }
        let filters = _.clone(newQuery);
        delete filters["page"];

        if (!_.isEqual(newQuery, this.state.query)) {
            console.log("render", newQuery, this.state.query);
            this.getList(newQuery);
            return (<div></div>);
        }
        let company = _.find(this.state.companies, x => x.id == this.state.query.company)
        if(company)
            company.subgroupName = (_.find(company.subgroupsArray, x => x.id == this.state.query.subgroup) || {name:""}).name;
        let pages = []
        let page = parseInt(this.state.query.page) || 0
        let lastPage = Math.floor(this.state.count / 1000)

        if(page == 0) {
            pages.push(page)
            if(page+1 <= lastPage)
                pages.push(page+1)
            if(page+2 <= lastPage)
                pages.push(page+2)
        }else if(page == lastPage){
            if(page-2 >=0)
                pages.push(page-2)
            if(page-1 >=0)
                pages.push(page-1)
            pages.push(page)
        }else{
            if(page-1 >=0)
                pages.push(page-1)
            pages.push(page)
            if(page+1 <= lastPage)
                pages.push(page+1)
        }

        return (
            <div>
                <div className="card shadow w100">
                    <div className="card-header py-3">
                        {company && <h2>Users of
                            <Link to={`/users?company=` + company.id}> {company.name}</Link>
                            {company.subgroupName && <span> / {company.subgroupName}</span>}
                        </h2>}
                        {!filters.company && <h2 className="m-0 font-weight-bold">Users</h2>}
                    </div>
                    <div className="card-body">
                        { !filters.company && <div className="row">
                            <div className="col col-6">
                                <Formik
                                    initialValues={{id: ''}}
                                    onSubmit={this.find}
                                >
                                    {({
                                          values,
                                          handleSubmit,
                                          handleChange,
                                          submitForm,
                                          setFieldValue,
                                          isSubmitting
                                      }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-row align-items-center">
                                                <div className="col-6">
                                                    <input className="form-control"
                                                           type="text"
                                                           name="id"
                                                           onChange={handleChange}
                                                           value={values.id}
                                                    />
                                                </div>
                                                <div className="col-auto">
                                                    <button type="button" className="btn btn-icon btn-primary ml-1" onClick={() => {
                                                        setFieldValue("action", "byId");
                                                        submitForm();
                                                    }}>
                                                        <span className="fas fa-fw fa-search"></span> Find by id
                                                    </button>
                                                    <button type="button" className="btn btn-icon btn-success ml-1" onClick={() => {
                                                        setFieldValue("action", "byName");
                                                        submitForm();
                                                    }}>
                                                        <span className="fas fa-fw fa-search"></span> Find by login
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>}

                        <div className="row">
                           {/* {Object.values(filters).some(x => !!x) &&
                            <div className="col col-12">
                                <div className="alert alert-info my-2">
                                    <div className="text-info">Filters</div>
                                    { Object.keys(filters).map((keyName, i) =>
                                        <div key={"filter"+keyName}>{filters[keyName] && <div><span className="text-gray-600">{keyName}:</span> <b>{filters[keyName]}</b></div>}</div>
                                    )}
                                </div>
                            </div>}*/}
                        </div>
                        {company && <div className="row">
                            <div className="col col-12 pt-4">
                                Subgroups:
                                {company.subgroupsArray.map((x, i) =>
                                    <Link key={"dasdq" + i} to={`/users?company=${company.id}&subgroup=${x.id}`}> {x.name}</Link>
                                )}
                            </div>
                        </div>}
                        <div className="row">
                            <div className="col col-12 pt-4">
                                {company && this.authContext.user.role=="full" && <div className="l">
                                    <button type="button" className="btn btn-sm btn-icon btn-primary" onClick={this.redirect(`/companies/${company.id}`)}>
                                        <span className="fas fa-fw fa-edit"></span> Edit company
                                    </button>
                                </div>}
                                <div className="r user-select-none">
                                    <button type="button" className="btn btn-icon btn-info ml-4 p-1" onClick={this.showModalTableCols}><span className="fas fa-fw fa-cog"></span> Settings</button>
                                </div>
                                <div className="r user-select-none">
                                    <ul className="pagination">
                                        <li className={"page-item " + (page == 0 ? "disabled" : "")}>
                                            {page != 0 && <Link className="page-link" to={"/users?"+ new URLSearchParams(Object.assign(newQuery, {page:page-1}))}>Previous</Link>}
                                            {page == 0 && <span className="page-link">Previous</span>}
                                        </li>
                                        { pages.map((p,i) =>
                                            <li key={`p`+p} className={`page-item ${page == p ? "active" : ""}`}>
                                                <Link className="page-link" to={"/users?" + new URLSearchParams(Object.assign(newQuery, {page:p}))}>{ p+1 }</Link>
                                            </li>
                                        )}
                                        <li className={"page-item " + (page == lastPage ? "disabled" : "")}>
                                            {page != lastPage && <Link className="page-link" to={"/users?"+ new URLSearchParams(Object.assign(newQuery, {page:page+1}))}>Next</Link>}
                                            {page == lastPage && <span className="page-link">Next</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className="r p-2 pr-4">Showing {page+1} to {lastPage+1} of {this.state.count} users</div>
                            </div>
                        </div>
                        <div id="tableCols" className="modal" tabIndex="-1" role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-xl" role="document">
                                <div className="modal-content">
                                    <form>
                                        <div className="modal-header">
                                            <h5 className="modal-title">Settings</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            { this.tableCols.map((x,i)=>
                                                <div key={"ch"+i} className="form-check form-switch m-2">
                                                    <input className="form-check-input" type="checkbox" id={"ch"+x} checked = { this.state.tableCols.includes(x) } onChange={this.selectTableCol(x)}/>
                                                    <label className="form-check-label" htmlFor={"ch"+x}>{x}</label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/*<div className="row">
                            <div className="col col-12 pt-4">
                                <div className="l user-select-none d-inline-block">
                                    { this.tableCols.map((x,i)=>
                                        <div key={"ch"+i} className="form-check form-switch d-inline mr-4 m-1">
                                            <input className="form-check-input" type="checkbox" id={"ch"+x} checked = { this.state.tableCols.includes(x) } onChange={this.selectTableCol(x)}/>
                                            <label className="form-check-label" htmlFor={"ch"+x}>{x}</label>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>*/}
                        <div className="row">
                            <div className="col col-12 pb-2">
                                <div className="table-responsive">
                                    <table className="dataTable table dataTable table-align-middle table-bordered table-sm"
                                           width="100%"
                                           cellSpacing="0">
                                        <thead>
                                        <tr>
                                            <th className={ this.state.sortKey == "idk" && (this.state.sortAsc ? "sorting_desc" : "sorting_asc") || "sorting"} onClick={this.sort("idk")}>id</th>
                                            {this.state.tableCols.includes("login") && <th className={ this.state.sortKey=="login" && (this.state.sortAsc ? "sorting_desc" : "sorting_asc") || "sorting"} onClick={this.sort("login")}>login</th>}
                                            {this.state.tableCols.includes("company") && <th className={ this.state.sortKey=="companyName" && (this.state.sortAsc ? "sorting_desc" : "sorting_asc") || "sorting"} onClick={this.sort("companyName")}>company</th>}
                                            {this.state.tableCols.includes("subgroup") &&  <th className={ this.state.sortKey=="subgroup" && (this.state.sortAsc ? "sorting_desc" : "sorting_asc") || "sorting"} onClick={this.sort("subgroup")}>subgroup</th>}
                                            {this.state.tableCols.includes("total") &&  <th className={ this.state.sortKey=="totalScore" && (this.state.sortAsc ? "sorting_desc" : "sorting_asc") || "sorting"} onClick={this.sort("totalScore")}>total</th>}
                                            {[1,2,3,4].map((l, i) => {
                                                let list = []
                                                if(this.state.tableCols.includes("level"+l)) {
                                                    list.push(<th key={"lh"+l} className={this.state.sortKey == "l" + l && (this.state.sortAsc ? "sorting_desc" : "sorting_asc") || "sorting"} onClick={this.sort("l" + l)} title={"Scores in level " + l}>m{l}</th>)
                                                    list.push(<th key={"mlh"+l} className={this.state.sortKey == "mgl" + l && (this.state.sortAsc ? "sorting_desc" : "sorting_asc") || "sorting"} onClick={this.sort("mgl" + l)} title={"Mini-games scores in level " + l}>n{l}</th>)
                                                    list.push(<th key={"tlh"+l} className={this.state.sortKey == "tl" + l && (this.state.sortAsc ? "sorting_desc" : "sorting_asc") || "sorting"} onClick={this.sort("tl" + l)} title={"Test scores in level " + l}>t{l}</th>)
                                                }
                                               return list;
                                            })}
                                            {this.state.tableCols.includes("updated") && <th className={ this.state.sortKey=="updatedAd" && (this.state.sortAsc ? "sorting_desc" : "sorting_asc") || "sorting"} onClick={this.sort("updatedAd")}>updated</th>}
                                            {this.state.tableCols.includes("created") && <th className={ this.state.sortKey=="createdAt" && (this.state.sortAsc ? "sorting_desc" : "sorting_asc") || "sorting"} onClick={this.sort("createdAt")}>created</th>}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state && this.state.users && this.state.users.map((u, i) =>
                                            <tr key={`user` + u.idk}>
                                                <td onClick={this.redirect(`/users/${u.idk}`)}>{u.idk}</td>
                                                {this.state.tableCols.includes("login") && <td onClick={this.redirect(`/users/${u.idk}`)}>{u.login}</td>}
                                                {this.state.tableCols.includes("company") &&<td><Link to={`/users?company=` + u.company}>{u.companyName}</Link></td>}
                                                {this.state.tableCols.includes("subgroup") &&<td><Link to={`/users?company=${u.company}&subgroup=${u.subgroup}`}>{u.subgroupName}</Link></td>}
                                                {this.state.tableCols.includes("total") &&<td onClick={this.redirect(`/users/${u.idk}`)} className="text-right">
                                                    {this.state.tableCols.includes("percents") ? Math.round(u.totalScore / u.maxTotalScore * 100)+"%": u.totalScore}
                                                </td>}
                                                {[1,2,3,4].map((l, i) => {
                                                    let list = []
                                                    if(this.state.tableCols.includes("level"+l)) {
                                                        list.push(<td key={u.idk+"l"+l} onClick={this.redirect(`/users/${u.idk}`)} className={`text-right text-col-l${l}`}>
                                                            { this.state.tableCols.includes("percents") ? Math.round(u[`l` + l]/this.state.scores[i].l * 100)+"%" : u[`l` + l] }
                                                        </td>)
                                                        list.push(<td key={u.idk+"ml"+l} onClick={this.redirect(`/users/${u.idk}`)} className={`text-right text-col-l${l}`}>
                                                            { this.state.tableCols.includes("percents") ? Math.round(u[`mgl` + l]/this.state.scores[i].m * 100)+"%" : u[`mgl` + l] }
                                                        </td>)
                                                        list.push(<td key={u.idk+"tl"+l} onClick={this.redirect(`/users/${u.idk}`)} className={`text-right text-col-l${l}`}>
                                                            { this.state.tableCols.includes("percents") ? Math.round(u[`tl` + l]/this.state.scores[i].t * 100)+"%" : u[`tl` + l] }
                                                        </td>)
                                                    }
                                                    return list;
                                                })}
                                                {this.state.tableCols.includes("updated") && <td onClick={this.redirect(`/users/${u.idk}`)}>{dateFormat(new Date(u.updatedAt), "dd-mm-yyyy HH:MM:ss")}</td>}
                                                {this.state.tableCols.includes("created") && <td onClick={this.redirect(`/users/${u.idk}`)}>{dateFormat(new Date(u.createdAt), "dd-mm-yyyy HH:MM:ss")}</td>}
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
