import React, {Component} from 'react';
import {
    Link, Redirect
} from "react-router-dom";
import {Formik} from 'formik';
import {AuthContext, useAuth} from "../context/auth";
import _ from 'lodash';
import UsersList from './UsersList'
import config from '../config';
import JsonEditorComponent from '../JsonEditorComponent'

export default class User extends Component {
    static contextType = AuthContext
    constructor(props, context) {
        super(props);
        this.authContext = context;
        this.state = {user: null, userId: null, userData: null};
        this.updateUser = this.updateUser.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.removeUser = this.removeUser.bind(this);
        this.saveUserTable = this.saveUserTable.bind(this);
        this.getScores = this.getScores.bind(this);
        this.getScores();
    }

    getScores()  {
        fetch(`${config.API()}maxScores`)
            .then(response => response.json())
            .then(data => {
                if(data && data.scores) {
                    this.setState(data)
                }else{
                    this.setState({scores: [{l: 0, m: 0, t: 0,}, {l: 0, m: 0, t: 0}, { l: 0, m: 0, t: 0}, {l: 0, m: 0, t: 0}]})
                }
            }).catch(function(err) {
        });
    }

    saveUserTable(request) {
        if(this.authContext.user.role!="full")
        {
            alert("Access error");
            return;
        }
        this.setState({saving: true});
        let userId = this.state.userId
        fetch(`${config.API()}user/${userId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.authContext.authToken,
                'Access-Control-Allow-Origin': '*',
                'Cross-Origin-Resource-Policy': 'cross-origin'
            },
            body: JSON.stringify(request)
        })
            .then(response => response.json())
            .then(data => {
                this.updateUser({id: userId});
                this.setState({saving: false});
            });

    }

    saveUser(data) {
        this.saveUserTable({
            state: data
        });
    }

    updateUser(values) {
        fetch(`${config.API()}user/${values.id}`)
            .then(response => response.json())
            .then(data => {
                fetch(`${config.API()}companies`)
                    .then(response => response.json())
                    .then(companies => {
                        let form = {
                            company: data.user.company || "",
                            subgroup: data.user.subgroup || "",
                            name: data.user.name || "",
                            login: data.user.login || "",
                            lastname: data.user.lastname || "",
                            pl: data.user.pl || "",
                        }
                        let total = 0;
                        let levels = {}
                        for(let i = 0; i < 4; i++){
                            let n = i+1;
                            levels["ep"+n] = UsersList.getScoreFromEpisode( _.get(data.user, `state.lsd[${i}]ep`));
                            levels["ng"+n] = UsersList.getScoreFromMiniGames( _.get(data.user, `state.lsd[${i}]ng`));
                            levels["ftg"+n] = UsersList.getScoreFromMiniGames( _.get(data.user, `state.lsd[${i}]ftg`));
                            total += levels["ep"+n] + levels["ng"+n] + levels["ftg"+n]
                        }
                        levels.totalScore = total;
                        for(let o of companies.rows){
                            o.subgroups = JSON.parse(o.subgroups)
                        }
                        this.setState({
                            user: data.user.state,
                            userId: values.id,
                            userData: data.user,
                            companies: companies.rows,
                            form,
                            levels
                        })
                        //setSubmitting(false);
                    })
            }).catch(function (err) {
            console.error(err);
        });
    }

    removeUser() {
        if (window.confirm("Confirm")) {
            const userId = this.state.userId;
            fetch(`${config.API()}user/remove/${userId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.authContext.authToken,
                    'Access-Control-Allow-Origin': '*',
                    'Cross-Origin-Resource-Policy': 'cross-origin'
                }
            }).then(response => response.json())
                .then(data => {
                    this.props.history.push("/users")
                }).catch(function (err) {
            });
        }
    }

    render() {
        if(!this.state.scores)
            return null;

        let {id} = this.props.match.params;
        if (id && this.state.userId != id) {
            this.updateUser({id});
            return (<div></div>);
        }
        return (
            <div className="container-max-height">
                <div className="card shadow w100">
                    <div className="card-header py-3">
                        <h2 className="m-0 font-weight-bold l"> User {this.state.userId} </h2>
                        <JsonEditorComponent className="r" data={this.state.user} saveHandler={this.saveUser}/>
                    </div>
                    <div className="card-body">
                        <AuthContext.Consumer>
                            {value => <div>The answer is {value.user.id}</div>}
                        </AuthContext.Consumer>
                        <div className="row">
                            <div className="col col-12">
                        {this.state.form && <Formik
                            initialValues={this.state.form}
                            onSubmit={this.saveUserTable}>
                            {({
                                  values,
                                  handleSubmit,
                                  handleChange,
                                  isSubmitting
                              }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label">Company</label>
                                        <div className="col-sm-10">
                                            <select className="form-control" name="company" onChange={handleChange} value={values.company}>
                                                <option value="" label="None" />
                                                { this.state.companies && this.state.companies.map((x,i)=>
                                                    <option value={x.id} label={x.name} />
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label">Subgroup {values.subgroup}</label>
                                        <div className="col-sm-10">
                                            <select className="form-control" name="subgroup" onChange={handleChange} value={values.subgroup}>
                                                <option value="" label="None" />
                                                { _.find(this.state.companies, x => x.id == values.company) && _.find(this.state.companies, x => x.id == values.company).subgroups.map((x,i)=>
                                                    <option value={x.id} label={x.name} />
                                                )}
                                            </select>

                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label">login</label>
                                        <div className="col-sm-10">
                                            <input className="form-control" type="text" name="login" onChange={handleChange} value={values.login}/>
                                        </div>
                                    </div>
                                    {this.authContext.user.role=="full" && <div className="form-group row">
                                        <label className="col-sm-2 col-form-label">Password</label>
                                        <div className="col-sm-10">
                                            <input className="form-control" type="text" name="pl" onChange={handleChange} value={values.pl}/>
                                        </div>
                                    </div>}
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label">FirstName</label>
                                        <div className="col-sm-10">
                                            <input className="form-control" type="text" name="name" onChange={handleChange} value={values.name}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label">LastName</label>
                                        <div className="col-sm-10">
                                            <input className="form-control" type="text" name="lastname" onChange={handleChange} value={values.lastname}/>
                                        </div>
                                    </div>
                                    {this.authContext.user.role=="full" && <button type="button" className="btn btn-icon btn-danger ml-1 r" onClick={this.removeUser}><span className="fas fa-fw fa-remove"></span> Remove </button>}
                                    {this.authContext.user.role=="full" && <button type="submit" className="btn btn-icon btn-success ml-1 r"><span className="fas fa-fw fa-save"></span> {this.state.saving ? "Saving" : "Save"} </button>}
                                </form>
                            )}
                        </Formik>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-12 pb-2">
                                <div>
                                    <h3>Percents</h3>
                                    <table className="dataTable table table-align-middle table-bordered table-sm"
                                           width="100%"
                                           cellSpacing="0">
                                        <thead>
                                        <tr>
                                            <th>Level</th>
                                            <th className="text-right">Total scores {this.state.levels.totalScore}</th>
                                            <th className="text-right">Mini-games scores</th>
                                            <th className="text-right">Negotiation scores</th>
                                            <th className="text-right">Test scores</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {[1,2,3,4].map((l, i) =>
                                            <tr key={"asd"+i}>
                                                <td >{l}</td>
                                                <td className="text-right">{
                                                    Math.round(100*(this.state.levels["ep"+l] + this.state.levels["ng"+l] + this.state.levels["ftg"+l])/
                                                        (this.state.scores[i].l+this.state.scores[i].m+this.state.scores[i].t))
                                                }%</td>
                                                <td className="text-right">{Math.round(this.state.levels["ep"+l] / this.state.scores[i].l* 100) }%</td>
                                                <td className="text-right">{Math.round(this.state.levels["ng"+l] / this.state.scores[i].m* 100) }%</td>
                                                <td className="text-right">{Math.round(this.state.levels["ftg"+l] / this.state.scores[i].t* 100) }%</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                    <h3>Values</h3>
                                    <table className="dataTable table table-align-middle table-bordered table-sm"
                                           width="100%"
                                           cellSpacing="0">
                                        <thead>
                                        <tr>
                                            <th>Level</th>
                                            <th className="text-right">Total scores {this.state.levels.totalScore}</th>
                                            <th className="text-right">Mini-games scores</th>
                                            <th className="text-right">Negotiation scores</th>
                                            <th className="text-right">Test scores</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {[1,2,3,4].map((l, i) =>
                                            <tr key={"asd"+i}>
                                                <td>{l}</td>
                                                <td className="text-right">{this.state.levels["ep"+l] + this.state.levels["ng"+l] + this.state.levels["ftg"+l]}</td>
                                                <td className="text-right">{this.state.levels["ep"+l]}</td>
                                                <td className="text-right">{this.state.levels["ng"+l]}</td>
                                                <td className="text-right">{this.state.levels["ftg"+l]}</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                    {this.state.user && this.state.user.lsd && this.state.user.lsd.map((level, i) =><div className="row" key={"levelsd"+i}>
                                        <div className="col col-12">
                                            <h3>Episode {i+1} points each scenes</h3>
                                            <h5>Mini-games</h5>
                                            <table className="dataTable table table-align-middle table-bordered table-sm"
                                                   width="100%"
                                                   cellSpacing="0">
                                                <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Type</th>
                                                    <th className="text-right">Scores</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {level.ep && level.ep.map((ep, i) =>{
                                                        let list = []
                                                        if(ep.md)
                                                            for(let md of ep.md){
                                                                list.push(<tr key={"ep"+i}>
                                                                    <td>{md.gi}</td>
                                                                    <td className="text-gray-500">{md.gt}</td>
                                                                    <td className="text-right">{md.p}</td>
                                                                </tr>)
                                                            }
                                                        return list
                                                    }
                                                )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col col-12">
                                            <h5>Levels</h5>
                                            <table className="dataTable table table-align-middle table-bordered table-sm"
                                                   width="100%"
                                                   cellSpacing="0">
                                                <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Type</th>
                                                    <th className="text-right">Scores</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {level.ng && level.ng.map((o, i) =>
                                                    <tr key={"ng"+i}>
                                                        <td>{o.gi}</td>
                                                        <td className="text-gray-500">{o.gt}</td>
                                                        <td className="text-right">{o.p}</td>
                                                    </tr>
                                                )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col col-12">
                                            <h5>Tests</h5>
                                            <table className="dataTable table table-align-middle table-bordered table-sm"
                                                   width="100%"
                                                   cellSpacing="0">
                                                <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Type</th>
                                                    <th className="text-right">Scores</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {level.ftg && level.ftg.map((o, i) =>
                                                    <tr key={"ftg"+i}>
                                                        <td>{o.gi}</td>
                                                        <td className="text-gray-500">{o.gt}</td>
                                                        <td className="text-right">{o.p}</td>
                                                    </tr>
                                                )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>)}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
