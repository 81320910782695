import React, { useState } from 'react';
import logo from './logo.svg';
import PrivateRoute from './PrivateRoute';
import {AuthContext, useAuth} from "./context/auth";
import $ from 'jquery';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useRouteMatch,
    Link
} from "react-router-dom";
import Permissions from './pages/Permissions'
import Users from './pages/Users'
import UsersList from './pages/UsersList'
import Company from './pages/Company'
import Companies from './pages/Companies'

import Vars from './pages/Vars'
import Settings from './pages/Settings'
import Files from './pages/Files'
import Login from './pages/Login'
import Home from './pages/Home'
import Redis from './pages/Redis'
import MaxScores from './pages/MaxScores'

import config from './config';
import 'rsuite/dist/styles/rsuite-default.css';

class App extends React.Component {
    constructor(props){
        super(props)
        let existingAuth = JSON.parse(localStorage.getItem("auth"));
        if(existingAuth && existingAuth.saveData && new Date(existingAuth.saveData).getTime()+ 24*60*60*1000 > new Date().getTime() ){
            this.state = {
                token: existingAuth.token,
                username: existingAuth.username,
                user: existingAuth.user,
            };
        }else{
            this.state = {
                token: null,
                username: null,
                user: null,
            };
        }

        this.setToken = this.setToken.bind(this);
    }

    setToken(data) {
        data.saveData = new Date().getTime()
        localStorage.setItem("auth", JSON.stringify(data));
        this.setState(data)
    }
    render() {
        return (
            <AuthContext.Provider value={{ authToken: this.state.token, setAuthToken: this.setToken, user: this.state.user }}>
            <Router>
                <div id="wrapper">
                    {this.state.token && <SideBar username={ this.state.username} />}
                    <div id="content-wrapper" className="l d-flex flex-column">
                        <div id="content">
                            <div className="container-fluid">
                                <div className="route mt-2">
                                    <Switch>
                                        <Route exact path="/login" component={Login} />
                                        <PrivateRoute  path="/redis" component={Redis} exact/>
                                        <PrivateRoute  path="/scores" component={MaxScores} exact/>
                                        <PrivateRoute  path="/vars" component={Vars} exact/>
                                        <PrivateRoute  path="/settings" component={Settings} exact/>
                                        <PrivateRoute  path="/files" component={Files} exact/>
                                        <PrivateRoute  path="/companies" component={Companies} exact/>
                                        <PrivateRoute path="/companies/:id" component={Company}/>
                                        <PrivateRoute  path="/users" component={UsersList} exact/>
                                        <PrivateRoute  path="/permissions" component={Permissions} exact/>
                                        <PrivateRoute path="/users/:id" component={Users}/>
                                        <PrivateRoute path="/" component={Home} exact/>
                                    </Switch>
                                </div>
                            </div>
                        </div>
                        <footer className="sticky-footer bg-light">
                            <div className="container my-auto">
                                <div className="copyright text-center my-auto">
                                </div>
                            </div>
                        </footer>
                    </div>
                    <div id="json" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabelJSON"
                         aria-hidden="true">
                        <div className="modal-dialog modal-xl" role="document">
                            <div className="modal-content">
                                <form>
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">State</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <pre id="json-display"></pre>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                        <button id="saveJson" className="btn btn-primary" type="button" onClick={ this.props.saveHandler }>
                                            <span className="fas fa-fw fa-save"></span>
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Router>
            </AuthContext.Provider>
        );
    }
}
function SideBar(props){
    const { setAuthToken } = useAuth();
    const [isHide, setIsHide] = useState(false);

    function sideBarClick(e) {
        e.preventDefault();
        $("body").toggleClass("sidebar-toggled");
        setIsHide(!isHide)
    }
    function logOut(e) {
        e.preventDefault();
        setAuthToken({token: null, username: "guest"});
    }

    return (
        <div>
            <ul className={"l navbar-nav bg-gradient-dark sidebar sidebar-dark accordion " + (isHide && "toggled")}
                id="accordionSidebar">
                <Link className="sidebar-brand sidebar-brand-liu-own align-items-center justify-content-center" to = "/">
                    <div className="sidebar-brand-icon">
                        <img src='https://www.themakergroup.com/paper/img/brand_logo_inverted.png' width={isHide ? "75" : "200"} id='imgLogo'/>
                    </div>
                    <br/>
                    <div className="sidebar-brand-text mx-3">LV1 Admin Panel</div>
                </Link>
                <hr className="sidebar-divider my-0"/>
                <MenuLink to="/companies" icon="fa fa-th-large" label="Companies" activeOnlyWhenExact={true}></MenuLink>
                <MenuLink to="/users" icon="fa-user" label="Users" activeOnlyWhenExact={true}></MenuLink>
                <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities"
                       aria-expanded="true" aria-controls="collapseUtilities">
                        <i className="fas fa-fw fa-wrench"></i>
                        <span>Utilities</span>
                    </a>
                    <div id="collapseUtilities" className="collapse" aria-labelledby="headingUtilities"
                         data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <CollapsedLink to="/scores" label="Max Scores" activeOnlyWhenExact={true}></CollapsedLink>
                            <CollapsedLink to="/permissions" label="Permissions" activeOnlyWhenExact={true}></CollapsedLink>
                            <CollapsedLink to="/files" icon="fa-file" label="Files" activeOnlyWhenExact={true}></CollapsedLink>
                            {/*<h6 className="collapse-header">Settings:</h6>*/}
                            {/*<CollapsedLink to="/vars" icon="fa-burn" label="Vars" activeOnlyWhenExact={true}></CollapsedLink>*/}
                            {/*<CollapsedLink to="/settings" icon="fa-tools" label="Settings" activeOnlyWhenExact={true}></CollapsedLink>*/}
                        </div>
                    </div>
                </li>
                <hr className="sidebar-divider my-0"/>
                <li className={" nav-item"}>
                    <Link onClick={logOut}  className="nav-link mask waves-effect waves-light rgba-white-slight " to="#">
                        <i className="fas fa-fw fa-sign-out-alt"></i>
                        <span>Logout ({props.username})</span>
                    </Link>
                </li>


                <hr className="sidebar-divider d-none d-md-block"/>
                <div className="text-center d-none d-md-inline">
                    <button className="rounded-circle border-0" id="sidebarToggle" onClick={sideBarClick}></button>
                </div>
            </ul>
            {/* <div className="test"></div> */}
        </div>
    )

}
function CollapsedLink({ label, to, icon, activeOnlyWhenExact }) {
    let match = useRouteMatch({
        path: to,
        exact: activeOnlyWhenExact
    });

    return (
        <Link className={(match && 'active') + " collapse-item mask waves-effect waves"} to={to}>{label}</Link>
    );
}
function MenuLink({ label, to, icon, activeOnlyWhenExact }) {
    let match = useRouteMatch({
        path: to,
        exact: activeOnlyWhenExact
    });

    return (
        <li className={(match && 'active') + " nav-item"}>
            <Link className="nav-link mask waves-effect waves-light rgba-white-slight " to={to}>
                <i className={icon + " fas fa-fw"}></i>
                <span>{label}</span>
            </Link>
        </li>
    );
}
export default App;
