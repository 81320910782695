import React, { Component } from 'react';
import _ from 'lodash';
import config from '../config';
import {
    Link,
    Redirect
} from "react-router-dom";
import {FieldArray, Formik} from "formik";
import {AuthContext} from "../context/auth";

export default class MaxScores extends Component {
    static contextType = AuthContext
    constructor(props, context) {
        super(props);
        this.authContext = context;
        this.state = {}
        this.getStore = this.getStore.bind(this);
        this.getStore();
    }
    getStore()  {
        fetch(`${config.API()}maxScores`)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if(data && data.scores) {
                    this.setState(data)
                }else{
                    this.setState({scores: [{l: 1000, m: 1000, t:  1000,},{l:  1000, m:  1000, t:  1000},{l:  1000, m:  1000, t:  1000},{l:  1000, m:  1000, t:  1000}]})
                }
            }).catch(function(err) {
                console.error(err);
            });
    }


    save(request){
        console.log(request)
        fetch(`${config.API()}maxScores`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Cross-Origin-Resource-Policy': 'cross-origin'
            },
            body: JSON.stringify({
                scores: request.scores
            })
        })
            .then(response => response.json())
            .then(data => {
                alert("saved")
            });
    }

    render() {
        if(this.authContext.user.role!= "full"){
            return <div className="alert alert-danger">Only full access</div>
        }

        if (!this.state || !this.state.scores) {
            return null;
        }
        console.log("render ", this.state)
        return (
            <div className="card shadow w100">
                <div className="card-header py-3"><h2 className="m-0 font-weight-bold"> Max Scores </h2></div>
                <div className="card-body">
                    <Formik
                        initialValues={this.state}
                        onSubmit={this.save}
                    >
                        {({
                              values,
                              handleSubmit,
                              handleChange,
                              submitForm,
                              setFieldValue,
                              isSubmitting
                          }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group row">
                                    <div className="col-1">
                                        Level number
                                    </div>
                                    <div className="col-2">
                                        Mini-games scores
                                    </div>
                                    <div className="col-2">
                                        Negotiation scores
                                    </div>
                                    <div className="col-2">
                                        Test scores
                                    </div>
                                    <div className="col-2">
                                        Total
                                    </div>
                                </div>
                                {values.scores.map((item, index) =>
                                    <div className="form-group row align-items-center" key={"scores" + index}>
                                        <div className="col-1">
                                            {index+1}
                                        </div>
                                        <div className="col-2">
                                            <input type="number" name={`scores[${index}].l`} className="form-control" onChange={handleChange} value={item.l}/>
                                        </div>
                                        <div className="col-2">
                                            <input type="number" name={`scores[${index}].m`} className="form-control" onChange={handleChange} value={item.m}/>
                                        </div>
                                        <div className="col-2">
                                            <input type="number" name={`scores[${index}].t`} className="form-control" onChange={handleChange} value={item.t}/>
                                        </div>
                                        <div className="col-2">
                                            {(item.l||0) + (item.m||0) + (item.t||0)}
                                        </div>
                                    </div>
                                )}
                                <div className="form-row align-items-center">
                                    <div className="col-12">
                                        <button type="button" className="btn btn-icon btn-primary ml-1 r" onClick={submitForm}>
                                            <span className="fas fa-fw fa-search"></span> Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        );
    }
}