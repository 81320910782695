import React from "react";

export default class JsonEditorComponent extends React.Component {
    constructor(props) {
        super(props);
        this.jsonEditor = null;
        this.jsonEdit = this.jsonEdit.bind(this);
        this.wid = Math.random()
    }
    jsonEdit(){
        if (this.jsonEditor == null && window.jQuery("#json-display").length > 0)
            this.jsonEditor = new window.JsonEditor('#json-display', {});

        if (this.jsonEditor)
            this.jsonEditor.load(this.props.data);

        const button =  window.jQuery('#saveJson');
        button.off();
        button.click((e)=>{
            window.jQuery('#json').modal('hide');
            this.props.saveHandler(this.jsonEditor.get())
        })
        window.jQuery('#json').modal('show');
    }

    render() {
        return(
            <button className={"btn btn-icon btn-primary ml-1 "+ this.props.className} onClick={this.jsonEdit}>
                <i className="fas fa-fw fa-edit"></i> JSON
            </button>
        )
    }
}