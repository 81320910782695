import React, { Component } from 'react';
import _ from 'lodash';
import config from '../config';
import {
    Link,
    Redirect
} from "react-router-dom";

export default class Vars extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vars: null
        }
        this.remove = this.remove.bind(this)
        this.add = this.add.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.remove = this.remove.bind(this)
        this.getStore();
    }
    getStore()  {
        fetch(`${config.API()}store/vars`)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                this.setState({vars: data})
            }).catch(function(err) {
                console.error(err);
            });
    }

    remove = (eventId) => {
        return (e) => {
            e.preventDefault();
            if (!e) e = window.event;                // Get the window event
            e.cancelBubble = true;                       // IE Stop propagation
            if (e.stopPropagation) e.stopPropagation();  // Other Broswers
            fetch(`${config.API()}store/vars/remove/${eventId}`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Cross-Origin-Resource-Policy': 'cross-origin'
                },
                body:"{}"
            })
                .then(response => response.json())
                .then(data => {
                    this.getStore();
                });
        }
    }
    add = (e) => {
        e.preventDefault();
        if (!e) e = window.event;                // Get the window event
        e.cancelBubble = true;                       // IE Stop propagation
        if (e.stopPropagation) e.stopPropagation();  // Other Broswers
        let data = this.state.vars || {}
        let id = Math.floor(Math.random()*100000)
        data[id] = {id: id, key: "", value:""}
        this.setState({vars: data})
    }
    save = (id) => {
        return (e) => {
            e.preventDefault();
            if (!e) e = window.event;                // Get the window event
            e.cancelBubble = true;                       // IE Stop propagation
            if (e.stopPropagation) e.stopPropagation();  // Other Broswers
            fetch(`${config.API()}store/vars/${id}`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Cross-Origin-Resource-Policy': 'cross-origin'
                },
                body: JSON.stringify({
                        item: this.state.vars[id]
                })
            })
                .then(response => response.json())
                .then(data => {
                    this.getStore();
                });
        }
    }
    handleChange = (id) => {
        return (e) => {
            e.preventDefault();
            if (!e) e = window.event;                // Get the window event
            e.cancelBubble = true;                       // IE Stop propagation
            if (e.stopPropagation) e.stopPropagation();  // Other Broswers
            let data = _.cloneDeep( this.state.vars)
            data[id][e.target.name] =  e.target.value
            this.setState({vars: data})
        }
    }
    render() {

        if (this.state.redirect) {
            return <Redirect push to={this.state.redirect} />;
        }

        let list = [];
        if(this.state && this.state.vars != null && Object.keys(this.state.vars).length > 0) {
            let sorted = _.sortBy(this.state.vars, ['key'])
            let n=0;
            while(sorted[0].key == "" && n < 100){
                let tmp = sorted.shift();
                sorted.push(tmp);
                n +=1;
            }
            for(let x of sorted){
               // let x = this.state.vars[k]
                list.push(
                    <tr className={(x.active ? "active" : "")} key={x.id}>
                        <td>{x.id}</td>
                        <td>
                            <input type="text"
                                   name = "key"
                                   className="form-control"
                                   onChange={this.handleChange(x.id)}
                                   value={x.key}
                            />

                        </td>
                        <td>
                            <input type="text"
                                   name = "value"
                                   className="form-control"
                                   onChange={this.handleChange(x.id)}
                                   value={x.value}
                            />
                            {x.title}
                        </td>
                        <td>
                            <button className="btn btn-outline-danger btn-circle btn-sm" onClick={this.remove(x.id)} ><i className="fa fa-remove"></i></button>
                            <button className="btn btn-outline-primary btn-circle btn-sm" onClick={this.save(x.id)} ><i className="fa fa-save"></i></button>
                        </td>
                    </tr>
                )
            }
        }

        return (
            <div className="card shadow w100">
                <div className="card-header py-3"><h2 className="m-0 font-weight-bold"> Vars </h2></div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="dataTable table table-align-middle table-bordered table-sm" width="100%"
                               cellSpacing="0">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Key</th>
                                    <th>Value</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {list}
                            </tbody>
                        </table>
                    </div>
                    <div className="r">
                        <button onClick={this.add} className="btn btn-icon btn-primary ml-1"><i className="fas fa-fw fa-plus-square"></i> Add </button>
                    </div>
                </div>
            </div>
        );
    }
}