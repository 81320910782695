import React, { Component } from 'react';
import _ from 'lodash';
import config from '../config';
import {
    Link,
    Redirect
} from "react-router-dom";
import JsonEditorComponent from "../JsonEditorComponent";

export default class Vars extends Component {
    constructor(props) {
        super(props);
        this.state = {
            settings: null
        }
        this.remove = this.remove.bind(this)
        this.add = this.add.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.remove = this.remove.bind(this)
        this.getStore();
    }

    getStore() {
        fetch(`${config.API()}store/settings`)
            .then(response => response.json())
            .then(data => {
                this.setState({settings: data})
            }).catch(function (err) {
            console.error(err);
        });
    }

    remove = (key) => {
        return (e) => {
            e.preventDefault();
            if (!e) e = window.event;                // Get the window event
            e.cancelBubble = true;                       // IE Stop propagation
            if (e.stopPropagation) e.stopPropagation();  // Other Broswers
            let obj = _.find(_.values(this.state.settings), x => x.key == key);
            let id = obj.id;
            fetch(`${config.API()}store/settings/remove/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Cross-Origin-Resource-Policy': 'cross-origin'
                },
                body: "{}"
            })
                .then(response => response.json())
                .then(data => {
                    this.getStore();
                });
        }
    }
    add = (e) => {
        e.preventDefault();
        if (!e) e = window.event;                // Get the window event
        e.cancelBubble = true;                       // IE Stop propagation
        if (e.stopPropagation) e.stopPropagation();  // Other Broswers
        let data = this.state.settings || {}
        let key = Math.floor(Math.random() * 100000)
        let id = "mew_" + key
        data[id] = {id, key, value: ""}
        this.setState({settings: data})
    }
    saveHandler = (key) => {
        return (data) =>{
            console.log(data);
            let obj = _.find(_.values(this.state.settings), x => x.key == key);
            let id = obj.id;
            obj.value =  JSON.stringify(data)
            fetch(`${config.API()}store/settings/${id}`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Cross-Origin-Resource-Policy': 'cross-origin'
                },
                body: JSON.stringify(obj)
            })
                .then(response => response.json())
                .then(data => {
                    this.getStore();
                });
        }
    }

    save = (key) => {
        return (e) => {
            e.preventDefault();
            if (!e) e = window.event;                // Get the window event
            e.cancelBubble = true;                       // IE Stop propagation
            if (e.stopPropagation) e.stopPropagation();  // Other Broswers
            let obj = _.find(_.values(this.state.settings), x => x.key == key);
            let id = obj.id;
            fetch(`${config.API()}store/settings/${id}`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Cross-Origin-Resource-Policy': 'cross-origin'
                },
                body: JSON.stringify({item: obj})
            })
                .then(response => response.json())
                .then(data => {
                    this.getStore();
                });
        }
    }
    handleChange = (key) => {
        return (e) => {
            e.preventDefault();
            if (!e) e = window.event;                // Get the window event
            e.cancelBubble = true;                       // IE Stop propagation
            if (e.stopPropagation) e.stopPropagation();  // Other Broswers
            let data = _.cloneDeep( this.state.settings)
            var obj = _.find(_.values(data), x => x.key == key);
            if(obj) {
                obj[e.target.name] = e.target.value
                this.setState({settings: data})
            }
        }
    }
    render() {

        if (this.state.redirect) {
            return <Redirect push to={this.state.redirect} />;
        }

        let list = [];
        if(this.state && this.state.settings != null && Object.keys(this.state.settings).length > 0) {
            /*let sorted = _.sortBy(this.state.settings, ['id'])
            let n=0;
            while(sorted[0].id == "" && n < 100){
                let tmp = sorted.shift();
                sorted.push(tmp);
                n +=1;
            }*/
            for(let k in this.state.settings){
                let x = this.state.settings[k]
                let parsed = {};
                try{
                    parsed = JSON.parse(x.value)
                }catch{}

                list.push(
                    <tr className={(x.active ? "active" : "")} key={x.key}>
                        <td>
                            <input type="text"
                                   name = "id"
                                   className="form-control"
                                   onChange={this.handleChange(x.key)}
                                   value={x.id}
                            />
                        </td>
                        <td>
                            <input type="text"
                                   name = "value"
                                   className="form-control"
                                   onChange={this.handleChange(x.key)}
                                   value={x.value}
                            />
                        </td>
                        <td>
                            <JsonEditorComponent className="l mr-2 btn-sm"  data={parsed} saveHandler={ this.saveHandler(x.key) }/>
                            <button className="btn btn-outline-danger btn-circle btn-sm" onClick={this.remove(x.key)} ><i className="fa fa-remove"></i></button>
                            <button className="btn btn-outline-primary btn-circle btn-sm" onClick={this.save(x.key)} ><i className="fa fa-save"></i></button>
                        </td>
                    </tr>
                )
            }
        }

        return (
            <div className="card shadow w100">
                <div className="card-header py-3"><h2 className="m-0 font-weight-bold"> Settings </h2></div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="dataTable table table-align-middle table-bordered table-sm" width="100%"
                               cellSpacing="0">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Value</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {list}
                            </tbody>
                        </table>
                    </div>
                    <div className="r">
                        <button onClick={this.add} className="btn btn-icon btn-primary ml-1"><i className="fas fa-fw fa-plus-square"></i> Add </button>
                    </div>
                </div>
            </div>
        );
    }
}