import React, { Component } from 'react';

export default class Login extends Component {

    render() {
        return (
            <div>
                <div className="card shadow w100">
                    <div className="card-header py-3"><h2 className="m-0 font-weight-bold"> Home </h2></div>
                    <div className="card-body">

                    </div>
                </div>
            </div>
        );
    }
}

