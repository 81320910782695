import React, { Component } from 'react';
import _ from 'lodash';
import config from '../config';
import {
    Link,
    Redirect
} from "react-router-dom";
import {Formik} from "formik";

export default class Vars extends Component {
    constructor(props) {
        super(props);
        this.state = { response: ""}
        this.cmd = this.cmd.bind(this);
    }
    cmd(request)  {
        fetch(`${config.API()}redis`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Cross-Origin-Resource-Policy': 'cross-origin'
            },
            body: JSON.stringify(request)
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                this.setState({response: data})
            });
    }

    render() {
        return (
            <div className="card shadow w100">
                <div className="card-header py-3 "><h2 className="m-0 font-weight-bold">Redis tools</h2></div>
                <div className="card-body">
                    <Formik
                        initialValues={{ cmd: ''}}
                        onSubmit= {this.cmd}
                    >
                        {({
                              values,
                              handleSubmit,
                              handleChange,
                              isSubmitting
                          }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="form-row align-items-center">
                                    <div className="col-6">
                                        <input className="form-control"
                                               type="text"
                                               name="cmd"
                                               onChange={handleChange}
                                               value={values.cmd}
                                        />
                                    </div>
                                    <div className="col-auto">
                                        <button  type="submit" className="btn btn-icon btn-success ml-1" ><span
                                            className="fas fa-fw fa-search"></span> Request
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
                <div className="card-body">
                     <pre>
                        {this.state.response.error ? this.state.response.error : JSON.stringify(this.state.response.response, null, 2)}
                    </pre>
                </div>
            </div>
        );
    }
}