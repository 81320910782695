import React, { Component } from 'react';
import _ from 'lodash';
import config from '../config';
import {
    Link,
    Redirect
} from "react-router-dom";
import {AuthContext} from "../context/auth";

export default class Files extends Component {
    static contextType = AuthContext
    constructor(props,context) {
        super(props);
        this.authContext = context;
        this.state = {
            uploadPath: "",
            storage: "",
            prefix: ""
        }
        this.uploadClick = this.uploadClick.bind(this)
        this.handleChangeUploadPath = this.handleChangeUploadPath.bind(this)
    }


    handleChangeUploadPath = (e) => {
            e.preventDefault();
            if (!e) e = window.event;                // Get the window event
            e.cancelBubble = true;                       // IE Stop propagation
            if (e.stopPropagation) e.stopPropagation();  // Other Broswers
            this.setState({uploadPath: e.target.value})
    }
    uploadClick =  (path) => {
        return (e) => {
            e.preventDefault();
            if (!e) e = window.event;                // Get the window event
            e.cancelBubble = true;                       // IE Stop propagation
            if (e.stopPropagation) e.stopPropagation();  // Other Broswers

            let oldForm = document.forms.uploadForm;
            let formData = new FormData(oldForm);
            fetch(`${config.API()}../files/${(path ? path : this.state.uploadPath)}`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Cross-Origin-Resource-Policy': 'cross-origin'
                }
            }).then(response => response.json()).then(data => {
               alert(JSON.stringify(data, null," "))
            });
        }
    }

    render() {

        if(this.authContext.user.id != 1){
            return <div className="alert alert-danger">Only root access</div>
        }

        if (this.state.redirect) {
            return <Redirect push to={this.state.redirect} />;
        }

        return (
                <div className="card shadow w100">
                <div className="card-header py-3">
                    <form action={`${config.API().replace("/admin","")}files/${this.state.uploadPath}`}  name="uploadForm" encType="multipart/form-data" method="POST">
                        <div className="form-group">
                            <label>Path {config.API().replace("/admin","")}uploads/webgl/</label>
                            <input className="form-control hidden" name="path" onChange={this.handleChangeUploadPath}/>
                        </div>
                        <input name="sign" value="1" hidden/>
                        <input name="uploads" value="1" hidden/>
                        <div className="form-group">
                            <input type="file" name="file"/>
                            <button type="button" className="btn btn-icon btn-sm btn-warning ml-3" onClick={this.uploadClick("webgl")}>
                                <span className="text">UploadFBWebGl</span>
                            </button>
                        </div>
                    </form>
                </div>

            </div>
        );
    }
}