import React, {Component} from 'react';
import {
    Link
} from "react-router-dom";
import {Formik, Field, FieldArray} from 'formik';
import _ from 'lodash';
import config from '../config';
import {AuthContext} from "../context/auth";

const dateFormat = require("dateformat");
export default class Permissions extends Component {
    static contextType = AuthContext
    constructor(props, context) {
        super(props);
        this.authContext = context;
        this.state = {users: null, companies: null};
        this.getList = this.getList.bind(this);
        this.save = this.save.bind(this);
        this.getCompanies = this.getCompanies.bind(this);
        this.getCompanies();
        this.getList();
    }

    getCompanies() {
        fetch(`${config.API()}companies?limit=5000`,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.authContext.authToken,
                'Access-Control-Allow-Origin': '*',
                'Cross-Origin-Resource-Policy': 'cross-origin'
            },
        })
            .then(response => response.json())
            .then(data => {
                for (let r of data.rows) {
                    r.subgroupsArray = JSON.parse(r.subgroups || "[]");
                }
                this.setState({companies: data.rows})
            })
    }

    getList() {
        fetch(`${config.API()}permissions`,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.authContext.authToken,
                'Access-Control-Allow-Origin': '*',
                'Cross-Origin-Resource-Policy': 'cross-origin'
            },
        })
        .then(response => response.json())
        .then(data => {
            for(let d of data){
                d.companiesArray = d.companies ? d.companies.split(",") : [];
            }
            console.log(data)
            this.setState({users: data})
        });
    }

    redirect(path) {
        return () => {
            this.props.history.push(path)
        }
    }
    save(request) {
        console.log(request);
        for(let u of request.users){
            u.companies = (u.companiesArray || []).join(",")
        }

        fetch(`${config.API()}permissions/save`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.authContext.authToken,
                'Access-Control-Allow-Origin': '*',
                'Cross-Origin-Resource-Policy': 'cross-origin'
            },
            body: JSON.stringify({items:request.users})
        })
            .then(response => response.json())
            .then(data => {
                alert("saved");
            });
    }

    render() {
        if(this.authContext.user.id != 1){
            return <div className="alert alert-danger">Only root access</div>
        }

        if (!this.state.companies)
            return null;

        if (!this.state.users)
            return null;

        return (
            <div>
                <div className="card shadow w100">
                    <div className="card-header py-3">
                        <h2 className="m-0 font-weight-bold">Permissions</h2>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col col-12">
                                <Formik
                                    initialValues={{users: this.state.users}}
                                    onSubmit={this.save}
                                >
                                    {({
                                          values,
                                          handleSubmit,
                                          handleChange,
                                          submitForm,
                                          setFieldValue,
                                          isSubmitting
                                      }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group row">
                                                {/*<div className="col-1">id</div>*/}
                                                <div className="col-1">login</div>
                                                <div className="col-1">role</div>
                                                <div className="col-3">companies (ctrl+click)</div>
                                                <div className="col-2">password</div>
                                                <div className="col-2">createdAt</div>
                                            </div>
                                            <FieldArray className="form-group row" name="users" render={arrayHelpers =>
                                                <div>
                                                    {values.users && values.users.map((item, index) =>
                                                        <div key={`users[${index}`} className="form-row align-items-start mt-2 pt-2 border-top">
                                                            {/*<div className="col-1">{item.id}</div>*/}
                                                            <div className="col-1">
                                                                <input type="text" name={`users[${index}].login`} className="form-control" onChange={handleChange} value={item.login || ""}/>
                                                            </div>
                                                            <div className="col-1">
                                                                <select className="form-control" name={`users[${index}].role`} onChange={handleChange} value={item.role || ""}>
                                                                    <option value="" label="None" />
                                                                    <option value="full" label="full" />
                                                                    <option value="read" label="read" />
                                                                </select>
                                                                {/*<input type="text" name={`users[${index}].role`} className="form-control" onChange={handleChange} value={item.role}/>*/}
                                                            </div>
                                                            <div className="col-3">
                                                                <Field component="select"
                                                                       className="form-control permission-multi-select"
                                                                       name={`users[${index}].companiesArray`}
                                                                       onChange={evt =>
                                                                           setFieldValue(
                                                                               `users[${index}].companiesArray`,
                                                                               [].slice
                                                                                   .call(evt.target.selectedOptions)
                                                                                   .map(option => option.value)
                                                                           )
                                                                       }
                                                                       multiple={true}>
                                                                    <option value="all" className="text-uppercase">
                                                                        all companies
                                                                    </option>
                                                                    { this.state.companies && this.state.companies.map(x => (
                                                                        <option key={`users[${index}]-company-${x.id}`} value={x.id}>
                                                                            {x.name}
                                                                        </option>
                                                                    ))}
                                                                </Field>
                                                                {/*<input type="text" name={`users[${index}].companies`} className="form-control" onChange={handleChange} value={item.companies}/>*/}
                                                            </div>
                                                            <div className="col-2">
                                                                <input type="text" name={`users[${index}].pwd`} className="form-control" onChange={handleChange} value={item.pwd || ""}/>
                                                            </div>
                                                            <div className="col-2 small">{item.createdAt}</div>
                                                            <div className="col-1">
                                                                { item.id != 1 && <button type="button" className="btn btn-icon btn-circle btn-outline-danger btn-sm"  onClick={() => arrayHelpers.remove(index)}>
                                                                    <span className="fas fa-fw fa-remove"></span>
                                                                </button>}
                                                                { item.id == 1 &&  <div className="text-info">root</div>}
                                                            </div>
                                                        </div>
                                                    )}
                                                    <button type="button" disabled={this.authContext.user && this.authContext.user.role == "read"} className="mt-2 btn btn-icon btn-primary" onClick={() => arrayHelpers.push({companies:[]})}>
                                                        <span className="fas fa-fw fa-plus"></span> Add
                                                    </button>
                                                </div>
                                            }>
                                            </FieldArray>
                                            <button type="button" className="btn btn-icon btn-success mt-4 r" onClick={submitForm}>
                                                <span className="fas fa-fw fa-search"></span> Save
                                            </button>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
