import React, {useState} from 'react';
import {Route, Redirect} from "react-router-dom";
import {Formik} from 'formik';
import config from '../config';
import {useAuth} from "../context/auth";

export default function Login() {
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [error, setError] = useState(null);
    const {setAuthToken} = useAuth();
    function submit(values, {setSubmitting}) {
        fetch(`${config.API()}auth`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Cross-Origin-Resource-Policy': 'cross-origin'
            },
            body: JSON.stringify(values)
        })
            .then(response => response.json())
            .then(data => {
                if (data.ok) {
                    setAuthToken({token: data.token, username: values.username, user: data.user});
                    setLoggedIn(true);
                } else {
                    setError(data.msg)
                }
            });
    }
    if (isLoggedIn) {
        return (<Redirect to="/users"/>);
    }

    return (
        <div className="container">
            <div className="card shadow w100">
                <div className="card-header py-3"><h2 className="m-0 font-weight-bold"> LV1 Admin Panel </h2></div>
                <div className="card-body">
                    <div className="row justify-content-center">
                        <div className="col-xl-10 col-lg-12 col-md-9">
                            <div className="card o-hidden border-0 shadow-lg my-5">
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                                        <div className="col-lg-6">
                                            <div className="p-5">
                                                <div className="text-center">
                                                    <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                                                </div>
                                                <Formik
                                                    initialValues={{username: '', password: ''}}
                                                    onSubmit={submit}
                                                >
                                                    {({
                                                          values,
                                                          handleSubmit,
                                                          handleChange,
                                                          isSubmitting
                                                      }) => (
                                                        <form className="user" onSubmit={handleSubmit}>
                                                            <div className="form-group">
                                                                <input type="text"
                                                                       name="username"
                                                                       className="form-control form-control-user"
                                                                       id="exampleInputEmail"
                                                                       placeholder="Enter Username..."
                                                                       onChange={handleChange}
                                                                       value={values.username}
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <input type="password"
                                                                       name="password"
                                                                       className="form-control form-control-user"
                                                                       id="exampleInputPassword"
                                                                       placeholder="Password"
                                                                       onChange={handleChange}
                                                                       value={values.password}
                                                                />
                                                            </div>
                                                            <button type="submit" className="btn btn-user btn-block btn-login">
                                                                <i className="fas fa-fw fa-sign-in-alt"></i> Login
                                                            </button>
                                                        </form>
                                                    )}
                                                </Formik>
                                                {error &&
                                                <div className="alert alert-danger mt-2" role="alert">
                                                    {error}
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

